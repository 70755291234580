import React, { useContext, useState } from 'react'
import { Modal, Icon } from 'antd'
import './index.scss'
import { observer } from 'mobx-react-lite'

import { ShareGroup } from 'components'
import { UserDetailContext } from 'store'
import { UserPreference } from '../InterestGroupSearchBlock/UserPreference'

const SplashScreen = () => {
  const [showShareGroup, setShowShareGroup] = useState(false)
  const [showPreferencesModal, setShowPreferencesModal] = useState(false)
  // const [showLinkedBlockModal, setShowLinkedBlockModal] = useState(false)
  const { user, showSplash, clearSplash } = useContext(UserDetailContext)
  return (
    <>
      <div style={{ display: 'none' }}>
        <ShareGroup
          openModal={() => setShowShareGroup(true)}
          show={showShareGroup}
          groupToShare={{
            groupName: user?.groupdetails?.[0]?.group?.groupName,
            description: user?.groupdetails?.[0]?.group?.description,
            groupLink: user?.groupdetails?.[0]?.group?.groupLink,
          }}
          closeModal={() => setShowShareGroup(false)}
          isIntroLinkSharable={true}
        />

        {/* <LinkedBlockModel
          showLinkedBlockModal={showLinkedBlockModal}
          setShowLinkedBlockModal={setShowLinkedBlockModal}
        /> */}

        <UserPreference
          showModal={showPreferencesModal}
          setShowModal={setShowPreferencesModal}
        />
      </div>

      <Modal
        title={false}
        centered
        visible={showSplash}
        maskClosable
        footer={false}
        width={750}
        destroyOnClose={true}
        onCancel={() => clearSplash()}
      >
        <div className="splash-screen-content">
          <div className="header">
            <h1 className="splash-title">Congrats, Onboarding Complete!</h1>
            <hr className="green-line-break" />
          </div>
          {/* <h2 className="splash-subtitle">
            Additional steps to enhance your 1:1s <br />
            to complete now or later: 
          </h2> */}
          <h1 className="splash-title">How I Work</h1>
          <div className="splash-inner-content">
            <h4>
              <div className="lioption">
                <span className="check-mark">
                  <Icon type="check" />
                </span>
                <span className="opt_text">
                  Our 1:1 meeting room rotates 15-25min convos. You can enter
                  the room anytime. You may be invited by peers to join live
                  convos. You can update your 1:1 matching{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    style={{ color: 'blue' }}
                    onClick={() => setShowPreferencesModal(true)}
                  >
                    Preferences.
                  </a>
                </span>
              </div>
            </h4>
            <h4>
              <div className="lioption">
                <span className="check-mark">
                  <Icon type="check" />
                </span>
                <span className="opt_text">
                  We also host scheduled 1:1 events & will let you know when a
                  relevant gathering is taking place. You can follow us & review
                  upcoming events{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    style={{ color: 'blue' }}
                    href="https://www.meetingmachine.me/events"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    here.
                  </a>
                </span>
              </div>
            </h4>
            {/* <h4>
              <div className="lioption">
                <span className="check-mark">
                  <Icon type="check" />
                </span>
                <span className="opt_text">
                  Community builders coordinate planned 1:1 networking events
                  too. You can join these events by entering our 1:1 meeting
                  room via the red button below.{' '}
                </span>
              </div>
            </h4>
            <div className="onDemand-Btn-container">
              <button className="onDemand-Btn" onClick={() => clearSplash()}>
                <Link to="/ondemand">Get 1:1 Intro Calls</Link>
              </button>
            </div> */}
            {/* <h4>
              <div className="lioption">
              <span className="check-mark">
                  <Icon type="check" />
                </span>
                <span className="opt_text">
                  Share your IntroLink to grow and optimize your 1:1s{' '}
                  <a
                    style={{ color: 'blue' }}
                    onClick={() => setShowShareGroup(true)}
                  >
                    here.
                  </a>
                </span>
              </div>
            </h4> */}
          </div>
        </div>
      </Modal>
    </>
  )
}
export default observer(SplashScreen)
